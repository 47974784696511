<template>
  <div
    v-if="items"
    class="page has-background-blue with-title"
  >
    <h1 class="page-title has-text-weight-bold has-text-centered is-size-5">
      {{ section_title }}
    </h1>
    <div class="container p-4">
      <div class="columns is-multiline is-mobile">
        <GenericMosaicItem
          v-for="item in items"
          :key="item.id"
          :data="item"
          @click="openContent(item)"
        />
        <div
          v-if="emptyResult"
          class="column is-12-mobile is-6-tablet is-offset-3-tablet"
        >
          <div class="no-results">
            No se han encontrado resultados.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GenericMosaicItem from "@/components/lists/GenericMosaicItem";

export default {
  name: 'PresentationCategoryList',
  components: {GenericMosaicItem},
  title: 'Presentaciones',
  data: function () {
    return {
      section_title: 'Presentaciones',
      items: null
    }
  },
  computed: {
    emptyResult() {
      if (!this.items) {
        return false
      }

      return this.items.length === 0
    },
    userToken() {
      return this.$store.state.token
    }
  },
  watch: {
    userToken() {
      this.loadContent()
    }
  },
  beforeMount() {
    this.loadContent()
  },
  methods: {
    async loadContent() {
      try {
        await this.$store.dispatch('initLoading')

        this.items = await this.$api.presentation.getCategoryList()

        await this.$store.dispatch('finishLoading')
      } catch (error) {
        await this.$store.dispatch('finishLoading')

        await this.handleContentError(error)
      }
    },
    openContent(item) {
      const itemId = item.id
      this.$router.push(`/presentations-category/${itemId}`)
    }
  }
}
</script>
